<template>
  <div class="solution-home">
    <div class="condition-box">
      <el-card class="box-card">
        <div class="input-form-item">
          <div style="display: flex">
            <div class="input-form-item">
              <div class="input-form-item-label">日期：</div>
              <el-date-picker
                v-model="surgeryDate"
                type="daterange"
                align="center"
                size="mini"
                style="margin-right: 10px"
                unlink-panels
                @change="handleNextDate"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </div>
          </div>
          <div>
            <el-button
              size="mini"
              type="primary"
              class="commonBtn"
              icon="el-icon-tickets"
              @click="getQueryInfo"
            >
              智能比对
            </el-button>
          </div>
        </div>
      </el-card>
    </div>
    <div class="table-content">
      <el-card style="width: 30%; height: 100%" class="box-card">
        <el-table
          class="big-table"
          :data="allList"
          @row-click="rowClick"
          border
          stripe
          height="75vh"
          highlight-current-row
          style="width: 100%"
          ref="leftTable"
        >
          <el-table-column
            prop="accessionNumberRis"
            align="center"
            label="检查号"
          ></el-table-column>
          <el-table-column prop="studyDate" align="center" label="检查时间">
            <template slot-scope="scope">
              <div>{{ scope.row.studyTime }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button @click="goDicom(scope.row)" type="text">查看影像</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <!-- 右边 -->
      <el-card style="width: 69%" class="box-card">
        <el-table
          v-loading="loading"
          style="width: 100%"
          :data="itemList"
          border
          height="75vh"
          :span-method="arraySpanMethod"
        >
          <el-table-column prop="lesionName" align="center" label="病变部位"></el-table-column>
          <el-table-column prop="groupName" align="center" label="测量指标类别"></el-table-column>
          <el-table-column prop="itemTitle" align="center" label="测量指标项目"></el-table-column>
          <el-table-column prop="itemValue" align="center" label="测量值">
            <template slot-scope="scope">
              {{ scope.row.itemValue }}{{ scope.row.measureUnit ? scope.row.measureUnit : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="upDown" align="center" label="标注图片" width="200">
            <template slot-scope="scope">
              <el-button @click="showImage(scope.row)" type="text">查看标注</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>

    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="[imgUrl]" />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

import { getFormatDate } from '../../../utils'
export default {
  name: 'Dicom',
  components: {
    ElImageViewer
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.patientsID) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 14)

          this.surgeryDate = [this.getFormatDate(start), this.getFormatDate(end)]

          this.CISReportInfo.studyStartDate = this.surgeryDate[0]
          this.CISReportInfo.studyEndDate = this.surgeryDate[1]
          this.getAllData()
        }
      },
      immediate: true
    },
    allList: function (val) {
      if (val && val.length > 0) {
        this.$nextTick(function () {
          this.rowClick(this.allList[0])
          this.$refs.leftTable.setCurrentRow(this.allList[0])
        })
      }
    }
  },
  data() {
    return {
      allList: [],
      selectedRow: [],
      itemList: [],
      loading: false,
      baseUrl: window.location.origin,
      showViewer: false,
      imgUrl: '',
      // 合并
      spanArr: [],
      pos: 0,
      spanArr2: [],
      pos2: 0,
      surgeryDate: '',
      // 导入日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              end.setTime(end.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      time_dialogVisible: false,
      CISReportInfo: {
        studyEndDate: null,
        studyStartDate: null
      }
    }
  },
  mounted() {},
  methods: {
    // 处理时间
    getFormatDate,
    getAllData() {
      if (!this.info.patientsID) {
        return this.$message.warning('未设置病案号')
      }
      const params = new FormData()
      params.append('startDate', this.CISReportInfo.studyStartDate)
      params.append('stopDate', this.CISReportInfo.studyEndDate)
      this.$api
        .post(`/v1/webconsole/pacs/patient/studies/${this.info.patientsID}`, params)
        .then(({ data }) => {
          if (data.status === 200) {
            this.allList = data.data
            this.itemList = []
          }
        })
    },
    rowClick(row) {
      this.selectedRow = row
      if (!row) {
        return this.$message.warning('未查询到当前信息')
      }
      this.loading = true
      this.$api
        .post(`/v1/webconsole/pacs/measure/details/study/${row.studyInstanceUid}`)
        .then(({ data }) => {
          if (data.status === 200) {
            this.itemList = this.flattenArray(data.data)
            this.getSpanArr(this.itemList)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    flattenArray(arr) {
      return arr.reduce((acc, obj) => {
        const flattenedItems = obj.groupList.flatMap((group) => {
          const { groupId, groupName, items } = group
          return items.map((item) => ({ ...obj, ...group, ...item, lesionName: obj.lesionName }))
        })

        return acc.concat(flattenedItems)
      }, [])
    },
    getSpanArr(data) {
      this.spanArr = [] // tip: 后台获取完成数据后，一定要重置spanArr,避免出现合并混乱！！！！！
      this.spanArr2 = []
      this.pos = 0
      this.pos2 = 0
      for (let i = 0; i < data.length; i++) {
        // 当为第一行时
        if (i === 0) {
          this.spanArr.push(1) // 1：独自占一行
          this.pos = 0 // 合并的起始行
          this.spanArr2.push(1) // 1：独自占一行
          this.pos2 = 0 // 合并的起始行
        } else {
          // 判断当前值是否与上一行的值相等，相等则进行合并
          // ----------------------column2--------------------------------
          if (data[i].lesionName && data[i].lesionName === data[i - 1].lesionName) {
            this.spanArr[this.pos] += 1 // 合并单元格：合并的行数 +1
            this.spanArr.push(0) // 0代表单元格是不需要显示, 已经被合并的单元格
          } else {
            this.spanArr.push(1) // 1代表当前这行的数据需要被显示
            this.pos = i // 存放需要合并行的索引
          }

          // column2
          if (
            data[i].lesionName === data[i - 1].lesionName &&
            data[i].groupId === data[i - 1].groupId
          ) {
            this.spanArr2[this.pos2] += 1
            this.spanArr2.push(0)
          } else {
            this.spanArr2.push(1)
            this.pos2 = i
          }
        }
      }
    },
    // 👇合并行和列 示例：第1列的“行”合并 & 第3列和第4列如果金额相等就“列”合并
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // 合并行方法：用于设置要合并的列(这里是指第一列合并)
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex] // 合并行数
        const _col = this.spanArr[rowIndex] > 0 ? 1 : 0 // 0：不显示
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex === 1) {
        const _row = this.spanArr2[rowIndex] // 合并行数
        const _col = this.spanArr2[rowIndex] > 0 ? 1 : 0 // 0：不显示
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 打开dicom
    goDicom(row) {
      const { href } = this.$router.resolve({
        path: `/dicomplayer?patientsID=${this.info.patientsID}&studyUUID=${row.studyInstanceUid}`
      })
      window.open(href, '_blank')
    },
    showImage(row) {
      this.imgUrl = this.baseUrl + `/api/v1/webconsole/downloadcenter/download` + row.imageUrl
      this.showViewer = true
    },
    closeViewer() {
      this.showViewer = false
    },
    handleNextDate() {
      if (this.surgeryDate?.length > 0) {
        this.CISReportInfo.studyStartDate = this.surgeryDate[0]
        this.CISReportInfo.studyEndDate = this.surgeryDate[1]
      } else {
        this.CISReportInfo.studyStartDate = null
        this.CISReportInfo.studyEndDate = null
      }

      this.getAllData()
    },
    getQueryInfo() {
      sessionStorage.setItem('patientIndex', this.info.patientsIndex)
      this.$router.push({
        path: `PacsInspection`
      })
    }
  }
}
</script>

<style scoped lang="less">
.solution-home {
  height: calc(100vh - 4rem);
  background: #f2f2f2;
  padding: 0 10px;

  .condition-box {
    padding: 10px 0 20px 0;

    .input-form-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .table-content {
    height: 80vh;
    display: flex;
    justify-content: space-between;
  }
  .big-table {
    /deep/tbody tr:hover > td {
      cursor: pointer;
    }
  }
}
</style>
